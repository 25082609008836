import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import RevealX from "../components/motion/RevealX";
import { BsDiamondFill } from "react-icons/bs";
import Reveal from "../components/motion/Reveal";
import ScrollToTop from "../utils/ScrooltoTop";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
const ServicePage = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div className="">
      <Helmet>
        <title>شهاب کمپینگ | سرویس ها</title>
        <meta name="og:title" content={"شهاب کمپینگ | سرویس ها"} />
      </Helmet>
      <Header />
      <ScrollToTop />
      <div>
        <img
          className="w-full lg:h-[500px]  object-cover"
          src="/banner2.jpg"
          alt=""
        />
      </div>
      <div dir={i18n.language === "en" ? "rtl" : "ltr"} className="container">
        {/* main sec */}
        <div className="lg:mt-[80px] mt-[30px]">
          <h1 className="custom_heading text-4xl text-center ">
            {t("services")}
          </h1>
          <Reveal>
            <p className="text-center mt-2">{t("services-text")}</p>
          </Reveal>
        </div>
        {/* first sec */}

        {/* secend sec */}
        <div className=" py-4 bg-white lg:my-[80px] my-[20px]  grid grid-cols-1 lg:grid-cols-2  gap-3 lg:gap-20">
          <div className="  col-span-1 order-2 lg:order-2 ">
            <RevealX>
              <div className="flex items-center gap-1 justify-end mb-3">
                <h3 className="custom_heading text-[1.75rem] text-end ">
                  {" "}
                  {t("logo-marketing")}
                </h3>

                <span>
                  <BsDiamondFill size={28} color="#EF4b4c" />
                </span>
              </div>
            </RevealX>
            <RevealX>
              <div className="text-end">{t("logo-desc")}</div>
            </RevealX>
          </div>
          <span className=" order-1 lg:order-1 h-[250px] lg:h-full">
            {" "}
            <Reveal>
              <img
                className="rounded shadow-md w-full  "
                src="/logoimg.jpeg"
                alt=""
              />
            </Reveal>
          </span>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ServicePage;
