import React from "react";
import Reveal from "./motion/Reveal";
import { Link } from "react-router-dom";
import RevealX from "./motion/RevealX";
import { useTranslation } from "react-i18next";

const AboutSection = () => {
  const [t, i18] = useTranslation("global");

  console.log(i18)
  return (
    <section
      style={{ backgroundImage: "url(/bg3.jpg)" }}
      class=" layout_padding bg-left bg-cover  bg-no-repeat relative "
    >
      <div class="container  ">
        <div class="grid grid-cols-1 gap-5 lg:gap-10 lg:grid-cols-2">
          <div class="order-2 lg:order-1">
            <div class="  p-3  ">
              <Reveal>
                <h3 class="custom_heading text-[2rem] text-end mb-3 relative z-10">
                  {t("start-avam")}
                </h3>
              </Reveal>
              <Reveal>
                <p
                  dir={i18.language == "en" ? "ltr" : "rtl"}
                  class="relative z-10"
                >
                  {t("about-sec-text")}
                </p>
              </Reveal>
              <RevealX head>
                <div className="mt-3 flex justify-end">
                  <Link
                    className="button-more w-fit bg-[#EF4b4c] px-8 py-2  text-white     border  border-[#EF4b4c] duration-100"
                    to="/about"
                  >
                    {t("more")}
                  </Link>
                </div>
              </RevealX>
            </div>
          </div>
          <div class="order-1 lg:order-2">
            <RevealX>
              <div class=" mx-3 sm:mt-0">
                <img className=" rounded shadow " src="/img3.jpg" alt="" />
              </div>
            </RevealX>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
