import React from "react";
import { Link } from "react-router-dom";

import {
  FaInstagram,
  FaFacebookF,
  FaTwitter,
  FaYoutube,
  FaLinkedinIn,
} from "react-icons/fa";
import { FaTelegram, FaWhatsapp } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const [t] = useTranslation("global");
  return (
    <>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d481.2673626319854!2d51.35714307008793!3d35.76142147803181!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1736425944948!5m2!1sen!2s"
        width="100%"
        height="400"
        allowfullscreen=""
        loading="lazy"
        title="Location"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
      <div className=" px-3 text-[#FFFF] bg-[#43506C]  lg:pt-[30px] lg:px-[150px]  z-50">
        <section class="">
          <div class="max-w-screen-xl px-4 pt-12 pb-2 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
            <nav class="flex flex-wrap justify-center -mx-5 -my-2">
              <div class="px-5 py-2">
                <Link
                  to="/service"
                  class="text-base leading-6  hover:text-gray-600"
                >
                  {t("services")}
                </Link>
              </div>
              <div class="px-5 py-2">
                <Link
                  to="/about"
                  class="text-base leading-6  hover:text-gray-600"
                >
                  {t("about-us")}
                </Link>
              </div>
              <div class="px-5 py-2">
                <Link
                  to="/contact"
                  class="text-base leading-6 hover:text-gray-600"
                >
                  {t("contact-us")}
                </Link>
              </div>
              <div class="px-5 py-2">
                <Link
                  to="/products"
                  class="text-base leading-6  hover:text-gray-600"
                >
                  {t("products")}
                </Link>
              </div>
              <div class="px-5 py-2">
                <Link to="/" class="text-base leading-6  hover:text-gray-600">
                  {t("home")}
                </Link>
              </div>
            </nav>
            <div class="flex justify-center mt-8 space-x-6">
              <Link
                to="https://t.me/"
                target="_blank"
                class=" hover:text-gray-300"
              >
                <FaTelegram size={26} />
              </Link>
              <Link
                to="https://www.instagram.com/"
                target="_blank"
                class=" hover:text-gray-300"
              >
                <FaInstagram size={26} />
              </Link>
              <Link
                to="https://wa.me/message/989136690792"
                target="_blank"
                class=" hover:text-gray-300"
              >
                <FaWhatsapp size={26} />
              </Link>
            </div>
            <p className="text-center">{t("footer-addres")}</p>
            <div class="  text-center gap-3 lg:gap-7 flex items-center flex-wrap lg:pb-0 justify-center sm:justify-around mt-4">
              <p class="text-center text-white">{t("reserved")}</p>
              <a
                href="https://sirir.ir/"
                target="_blank"
                rel="noreferrer"
                dir="ltr"
                className="flex gap-1 items-center"
              >
                Powered By{" "}
                <img
                  src="https://sirir.ir/logo.png"
                  className="w-8 object-contain items-center mb-1"
                  alt="Sirir logo"
                />
                Sirir
              </a>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Footer;
